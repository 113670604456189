.container {
    align-items: center;
    background-color: #ffffff;
    bottom: 0;
    color: #444444;
    display: flex;
    flex-direction: column;
    font-family: Soleil, sans-serif;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.errorImg {
    height: 154px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 154px;
}

.errorMsg {
    max-width: 340px;
    text-align: center;
}
