.container {
    align-items: center;
    display: flex;
}

.userProfileButton {
    color: #ffffff !important;
    padding: 8px 5px 8px 10px !important;
}

.accountCircle {
    font-size: 32px !important;
}

.chevronDown,
.chevronUp,
.name {
    font-size: 24px;
}

.profileItem {
    align-items: flex-start;
    background-color: var(--color-green-ultra-light, #cfebd8);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: var(--color-text, #444444);
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
}

.userId {
    font-size: 14px;
    line-height: 1;
}
